import { computed } from "vue";
import { Notify } from "quasar";

import backend from "@/api";
import { useStore } from "@/store";
import { useRouter, useRoute } from "@/routing/router";
import { loginLocales, notifies } from "@/services/useLocales";

const currentUser = computed(() => {
  const store = useStore();
  return store.state.account.current_user;
});

const currentUserId = computed(() => currentUser.value?.id);

const uiPromptsShow = computed(() => {
  return (currentUser.value && currentUser.value.ui_prompts_showed_at) || false;
});

const authToken = computed(() => {
  const store = useStore();
  return store.state.account.auth_token;
});

const useSignIn = async (params, context) => {
  const store = useStore();
  const router = useRouter();

  context.emit("setLoading", true);

  try {
    const response = await backend.sign_in(store.state.paths["sign_in"], params);

    store.commit("logIn", { user: response.data.user, token: response.data.token });

    const facilityId = store.state.account.current_user.facility_ids[0];
    await router.push({ name: "Dashboard", params: { facility_id: facilityId, type: "issues" } });
  } catch (e) {
    if (e.response && [401, 404].includes(e.response.status)) {
      await useReLoginRun(loginLocales.value["login_form.notifies.invalid_login"], context);
    }
  }
};

const useReLoginRun = async (msg = undefined, context) => {
  const store = useStore();
  const router = useRouter();
  const route = useRoute();

  Notify.create({
    badgeStyle: "display: none",
    message: msg || notifies.value.session_has_over,
  });

  store.commit("logOut");

  if (route.path === "/login") {
    setTimeout(() => context.emit("setLoading", false), 500);
  } else {
    await router.replace({ name: "Login" });
  }
};

const useReLogin = async (status, msg = undefined, context) => {
  if (status && status === 401) {
    await useReLoginRun(msg, context);
  }
};

export { useSignIn, useReLogin, useReLoginRun, currentUser, currentUserId, authToken, uiPromptsShow };
