<template lang="pug">
.form-step-four-event
  .form-step-four-event-title(@click="closeEvent()")
    .form-step-four-event-title-arrow
      q-icon(name="expand_more")
    .form-step-four-event-title-text {{ `${index + 1}. ${schedulerLocales["event"]}` }}
  .form-step-four-event-name-and-periodicity
    .form-step-four-event-name
      input-title(:title="schedulerLocales['eventName']", :required="true")
      q-input.form-step-one-name(v-model="eventEl.name", filled, no-error-icon, autocomplete="off")
        q-icon.cancel-string-field(name="cancel", v-if="eventEl.name", @click.stop="resetEventName(eventEl)")
    .form-step-four-event-periodicity
      input-title(:title="schedulerLocales['periodicity']", :required="true")
      q-input.form-step-one-name(:value="eventName", @click.stop="openPeriodForm", readonly, filled, no-error-icon)
    .form-step-four-event-checklist
      selectField(
        :label="schedulerLocales['checklist']",
        full-options-path="api/v3/checklists",
        :options-params="{ facility_id: facilityId }",
        :initial-value="eventEl.checklist",
        @change="val => updateChecklist(eventEl, val)"
      )
  .form-step-four-event-periodicity-form(v-if="periodForm")
    .form-step-four-event-periodicity-form-periods
      select-field(
        :initialValue="eventEl.periodType.id",
        :options="periodTypeOptions",
        :multiple="false",
        :showCancel="false",
        :label="schedulerLocales['issuesPeriodicity']",
        required,
        @change="val => updatePeriod(val, eventEl)"
      ) 
      .form-step-four-event-periodicity-form-periods-options(v-if="eventEl.periodType.id")
        .form-step-four-event-periodicity-form-periods-option(
          v-for="periodOption in eventEl.period",
          :key="periodOption.id",
          @click="setPeriodOptionSelected(periodOption)",
          :class="{ selected: periodOption.selected }"
        ) {{ periodOption.title }}
      .form-step-four-event-periodicity-form-period
        .form-step-four-event-periodicity-form-period-from
          input-title(:title="schedulerLocales['startedAt']", :required="true")
          date-field(
            :value="eventEl.startedAt",
            :minDate="todaysDate",
            @select-date="selectStartDate",
            :event="eventEl"
          )
        .form-step-four-event-periodicity-form-period-to
          input-title(:title="schedulerLocales['finishedAt']", :required="true")
          date-field(
            :value="eventEl.finishedAt",
            :minDate="eventEl.startedAt",
            @select-date="selectFinishDate",
            :event="eventEl"
          )
      .form-step-four-event-periodicity-form-deadline
        input-title(:title="schedulerLocales['deadline']", :required="true")
        q-input(v-model="eventEl.deadline", type="number", filled, no-error-icon, autocomplete="off")
    q-icon.form-step-four-event-periodicity-form-close(name="close", @click.stop="closePeriodForm()")
    .form-step-four-event-periodicity-form-submit(@click.stop="closePeriodForm()") {{ schedulerLocales["save"] }}

  .form-step-four-event-works
    .form-step-four-event-works-left.close
      .form-step-four-event-works-title(@click="closeWorks()")
        .form-step-four-event-arrow
          q-icon(name="expand_more")
        .form-step-four-event-works-title-text {{ schedulerLocales["works"] }}
      .form-step-four-event-works-form
        .form-step-four-event-works-column
          q-input.form-step-four-event-works-search(
            v-model="worksQuery",
            @input="val => searchWorks(eventEl, val)",
            filled
          )
            q-icon(name="search")
          .form-step-four-event-works-options(v-if="eventWorkLength > 0")
            .form-step-four-event-works-option(
              v-for="option in eventOptions",
              :key="option.id",
              :class="{ selected: option.selected }",
              @click="selectWork(eventEl, option)"
            ) 
              .form-step-four-event-works-option-icon
                q-icon(name="done")
              .form-step-four-event-works-option-text {{ option.title }}
            .form-step-four-event-works-observe-visibility(v-observe-visibility="reachedEndOfList")
          .form-step-four-event-works-options-plug(v-if="eventWorkLength === 0") {{ schedulerLocales["worksNotAllowed"] }}
        .form-step-four-event-works-options.selected(v-if="eventWorkLength > 0 || worksQuery.length > 0")
          .form-step-four-event-works-option(
            v-for="option in eventWorks",
            :key="option.id",
            :class="{ selected: option.selected }"
          ) 
            .form-step-four-event-works-option-name(@click="selectWork(eventEl, option)")
              .form-step-four-event-works-option-icon
                q-icon(name="done")
              .form-step-four-event-works-option-text {{ option.title }}
            .form-step-four-event-works-option-count
              .form-step-four-event-works-option-count-minus(@click="changeCount('-', option)") -
              .form-step-four-event-works-option-count-number {{ option.count + 1 }}
              .form-step-four-event-works-option-count-plus(@click="changeCount('+', option)") +
            .form-step-four-event-works-option-standardhours {{ calculateHours(option.count + 1, option.standard_hours) }}
            .form-step-four-event-works-option-cost {{ getCalculateCost(option.count + 1, option.cost) }}
    .form-step-four-event-works-right
      .form-step-four-event-works-calculator
        .form-step-four-event-works-calculator-hours
          input-title(:title="schedulerLocales['worksNormhours']", :required="false")
          q-input.form-step-one-name(:value="getAllHours(eventEl)", filled, no-error-icon, :disable="true")
        .form-step-four-event-works-calculator-costs
          input-title(:title="schedulerLocales['worksCost']", :required="false")
          q-input.form-step-one-name(:value="getAllCosts(eventWorks)", filled, no-error-icon, :disable="true")
  .form-step-four-event-description
    input-title(:title="schedulerLocales['description']", :required="false")
    q-input(type="textarea", v-model="eventEl.description", filled, autogrow)
</template>

<script setup>
import { computed, inject, ref, toRefs, onBeforeMount, onMounted } from "vue";
import i18n from "@/plugins/vue-i18n";
import { currentLocale } from "@/services/useLocales";
import { format, parse } from "date-fns";
import selectField from "../../../../shared/general_components/fields/selectField";
import dateField from "../../../../shared/general_components/fields/dateField";
import inputTitle from "./inputTitle.vue";

const props = defineProps({
  index: { type: Number, default: () => 1 },
  event: { type: Object, default: () => {} },
  facilityId: { type: Number || null, default: null },
});

const periodForm = ref(false);
const todaysDate = ref("");
const worksQuery = ref("");

const schedulerLocales = computed(() => i18n["messages"][currentLocale.value]["scheduler"]);
const dateLocales = computed(() => i18n["messages"][currentLocale.value]["date"]);
const eventEl = computed(() => props.event);
const index = computed(() => props.index);
const eventWorkLength = computed(() => eventEl.value.works.options.length);
const eventName = computed(() => eventEl.value.periodType.title || schedulerLocales.value["submit"]);
const eventWorks = computed(() => eventEl.value.works.items);
const eventOptions = computed(() => eventEl.value.works.options);

const { facility, periodTypeOptions } = inject("formVariables");

const openPeriodForm = () => {
  periodForm.value = true;
};

const getTodaysDate = () => {
  const date = new Date();
  todaysDate.value = format(date, "dd.MM.yyyy");
};

const reachedEndOfList = async reachedEnd => {
  if (reachedEnd && eventEl.value.works.hasNextPage) {
    await getWorks(eventEl.value, worksQuery.value);
  }
};

const closePeriodForm = () => {
  periodForm.value = false;
};

const {
  closeEvent,
  resetEventName,
  updatePeriod,
  getAllHours,
  getAllCosts,
  closeWorks,
  calculateHours,
  getCalculateCost,
  setPeriodOptionSelected,
  selectStartDate,
  selectFinishDate,
  changeCount,
  selectWork,
  getWorks,
  searchWorks,
  updateChecklist,
} = inject("formFunctions");

onBeforeMount(() => {
  getTodaysDate();
});
</script>
