<template lang="pug">
div
  .user-new-form.justify-center(v-if="dataHasLoaded")
    handle-form(v-if="refetchReady", @close-form="closeForm", :parentData="handleFormParentData")
    .new-edit-form(v-if="!refetchReady")
</template>

<script setup>
import { onBeforeUnmount, onMounted, ref, computed } from "vue";
import { useStore } from "@/store";
import { handleError } from "@/services/handleErrors";
import { useEmitter } from "@/services/useEmitter";
import { useRoute } from "@/routing/router";
import { currentUserId } from "@/services/useAuth";
import backend from "@/api";
import HandleForm from "@/components/shared/forms/Form";

const store = useStore();
const emitter = useEmitter();
const route = useRoute();

const props = defineProps({
  parentData: { type: Object, default: () => {} },
});

const emit = defineEmits(["close-form"]);

const path = ref("/api/v3/dynamic/issues");
const grid = ref(props.parentData.grid);
const callbackParams = ref(props.parentData.callback_params);
const formData = ref([]);
const dataHasLoaded = ref(false);
const refetchReady = ref(true);

const facilityId = computed(() => Number(route.params.facility_id));
const handleFormParentData = computed(() => ({
  method: "create",
  item: "issue",
  form_title: formData.value.form_header.title.add,
  title_icon_path: "add_header.svg",
  path: path.value,
  grid: grid.value,
  data: formData.value,
  additional_params: { facility_id: facilityId.value },
  requestUserId: currentUserId.value,
}));

const closeForm = data => {
  emit("close-form", data);
};

const refetchAndReformat = async param => {
  const formDataPath = `${path.value}/form_data`;
  const params = { [param.name]: param.value, facility_id: facilityId.value, request_user_id: currentUserId.value };

  try {
    refetchReady.value = false;

    const { data } = await backend.index(formDataPath, { params });

    const previousValues = _.cloneDeep(store.state.grid[grid.value].form);

    // фильтруем поля, у которых есть дети, выводим массив их имен
    const dependants = formData.value.fields
      .filter(elem => !!elem.watch?.parents?.find(el => el.parent === param.name))
      ?.map(el => el.name);

    dependants.forEach(el => store.commit("resetFormField", { grid_name: grid.value, field: el }));

    formData.value = data;

    const previousKeys = Object.keys(previousValues);

    refetchReady.value = true;

    // if (previousKeys.length > 0) {
    //   restoreUserValues(previousKeys, previousValues);
    // }
  } catch (e) {
    await handleError(e);
  }
};

// Функция восстновления сломана: при смене статуса не восстанавливается форма
// const restoreUserValues = (previousKeys, previousValues) => {
//   // восстановление пользовательских значений в форму
//   previousKeys.forEach(key => {
//     formData.value.fields.forEach((field, index) => {
//       if (field["name"] === key && previousValues[key].field) {
//         formData.value.fields[index].value = previousValues[key].field.value
//           ? previousValues[key].field.value
//           : previousValues[key].field;
//       }
//     });
//   });
// };

const loadFormData = async () => {
  const formDataPath = `${path.value}/form_data`;
  const params = { facility_id: facilityId.value, request_user_id: currentUserId.value };

  try {
    const response = await backend.index(formDataPath, { params });
    formData.value = response.data;

    const callbackKeys = Object.keys(callbackParams.value);

    if (callbackKeys.length > 0) {
      callbackKeys.forEach(key => {
        formData.value.fields.forEach(field => {
          if (field["name"] === key) {
            field["value"] = callbackParams.value[key];
          }
        });
      });
    }
  } catch (error) {
    await handleError(error);
  } finally {
    dataHasLoaded.value = true;
  }
};

onMounted(async () => {
  emitter.on("changeFormByField", refetchAndReformat);
  await loadFormData();
});

onBeforeUnmount(() => {
  emitter.off("changeFormByField", refetchAndReformat);
});
</script>

<script>
export default {
  name: "New",
};
</script>
