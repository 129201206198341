<template lang="pug">
div
  .select-fields.form-field.form-multiselect.filter(@click.stop="toggleFields", :class="selectFieldsClass")
    .row.items-center.select-fields__label {{ text }}
    .row.items-center.select-fields__icon
      inline-svg(v-if="showing", :src="require(`@/assets/icons/dynamic-issues/index/arrow-up.svg`)")
      inline-svg(v-else, :src="require(`@/assets/icons/dynamic-issues/index/arrow-down.svg`)")

  q-menu(
    content-class="select-fields__menu",
    v-model="showing",
    transition-show="jump-down",
    transition-hide="jump-up"
  )
    div
      q-list.select-fields__list
        select-fields-checkboxes(
          :list="list",
          :selectedAll="selectedAll",
          @change="onFieldChange",
          @change-all="onChangeAll"
        )
      .select-fields__buttons
        button(type="secondary", :label="currentLocales.cancel", :disable="loading", @click="hideFields")
        button(type="primary", :label="currentLocales.submit", :disable="loading", @click="submit")
</template>

<script setup>
import { ref, computed } from "vue";
import { backend } from "@/api";
import { handleError } from "@/services/handleErrors";
import { dynamicIssuesLocales } from "@/services/useLocales";
import Checkbox from "@/components/shared/general_components/inputs/Checkbox.vue";
import Button from "@/components/shared/general_components/inputs/Button.vue";
import draggable from "vuedraggable";
import SelectFieldsCheckboxes from "./SelectFieldsCheckboxes.vue";

const props = defineProps({
  /* 
    ID основной организации
  */
  facilityId: { type: Number, required: true },
});

const emit = defineEmits([
  /* 
    Отправка запроса на сервер для обновления выбранных полей успешно завершено
  */
  "submit",
]);

const loading = ref(false);
const showing = ref(false);
const list = ref([]);
const selectedAll = ref(false);

const selectFieldsClass = computed(() => ({
  "select-fields--active": showing.value,
  "select-fields--loading": loading.value,
}));

const currentLocales = computed(() => dynamicIssuesLocales.value.select_fields);
const text = computed(() => (loading.value ? currentLocales.value.loading : currentLocales.value.title));

const toggleFields = async () => {
  if (loading.value == true) {
    return;
  }

  if (showing.value == true && loading.value == false) {
    showing.value = false;
    return;
  }

  await loadFields();
  showing.value = true;
};

const hideFields = () => {
  showing.value = false;
};

const loadFields = async () => {
  try {
    loading.value = true;
    const response = await backend.index("api/v3/dynamic/issues/card", {
      params: { facility_id: props.facilityId },
    });

    // Sort by index and remove "index" field since we can reorder list
    list.value = orderByAndRemoveIndex(response.data);

    // Select all value based on items selection
    const allItemsSelected = list.value.every(item => item.value === true);
    selectedAll.value = allItemsSelected;
  } catch (error) {
    await handleError(error);
  } finally {
    loading.value = false;
  }
};

// Sort by index to ensure correct order. After that remove index to be compatible with vue draggable
// Recursively apply it to children (since they have same structure if present)
const orderByAndRemoveIndex = list => {
  return list
    .sort((a, b) => a.index - b.index)
    .map(item => ({
      label: item.label,
      name: item.name,
      value: item.value,
      disabled: item.disabled,
      children: item.children.length > 0 ? orderByAndRemoveIndex(item.children) : item.children,
    }));
};

const submit = async () => {
  // Map children to have same structure as parents
  // Apply index from draggable list (to apply new order)
  const fieldsList = list.value.map((item, index) => ({
    [item.name]: {
      index,
      value: item.value,
      children: item.children.map((item, index) => ({
        [item.name]: { index, value: item.value, children: item.children },
      })),
    },
  }));
  const fields = Object.assign(...fieldsList);

  try {
    await backend.patch(
      "api/v3/dynamic/issues/card",
      { facility_id: props.facilityId, issue_card: fields },
      { jsonRequest: true },
    );
    emit("submit");
    hideFields();
  } catch (error) {
    await handleError(error);
  }
};

const onFieldChange = newList => {
  list.value = newList;
};

const onChangeAll = opts => {
  selectedAll.value = opts.selectedAll;
  list.value = opts.newList;
};
</script>

<style lang="scss">
@import "../../../../assets/styles/forms/fields/checkbox";

.select-fields__menu {
  min-width: 350px;
  overflow-y: hidden;
  border-radius: 8px;
}
</style>

<style scoped lang="scss">
// If you change this value make sure to update SelectColumns.vue
$handle-size: 20px;

.select-fields__empty {
  width: $handle-size;
  height: $handle-size;
}

.select-fields {
  cursor: pointer;

  &--loading {
    cursor: wait;
  }

  &--active {
    cursor: pointer;
  }

  &__label {
    padding-left: 15px;
    width: 100%;
    color: var(--dynamic-issues-select-fields-label-color);
  }

  &__icon {
    padding-right: 10px;
  }

  &__list {
    overflow-y: scroll;
    max-height: 470px;
    padding: 10px 0 0 16px;
  }

  &__buttons {
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
    padding-top: 10px;
    box-shadow: 0px -8px 40px rgba(127, 135, 146, 0.1);
  }
}
</style>
