<template lang="pug">
.show-card-blocks
  div(v-for="(block, index) in data", :key="block.class")
    div(v-if="!block.details_btn")
      div(v-show="isBlockHidden(block)", :class="`show-card-info ${block.class}`")
        .flex.items-center(v-if="block.name")
          .row-title {{ block.name }}
          .row-remove-empty.q-ml-auto
            q-toggle(
              :label="issueLocales.hide_empty_fields",
              left-label,
              :value="hiddenBlocks",
              :val="block.class",
              @input="switchHiddenBlockHidden(block.class)",
              color="indigo-12"
            )
        .row-info-parts(v-for="(part, index) in block.parts", :key="`${block.class}${index}`")
          .spacer(v-if="block.spacer")

          template(:style="block.style", v-for="field in part")
            .row-info-field(v-if="isFieldHidden(block, field.value)", :key="field.value")
              span.row-label {{ field.label }}

              span.row-value(v-if="field.type === 'description'", v-html="row[field.value]")

              .form-field.form-multiselect(v-else-if="field.type === 'select'")
                multiselect(
                  :value="field.value",
                  track-by="id",
                  label="label",
                  :multiple="field.multiple",
                  placeholder="",
                  :show-labels="false",
                  :options="field.options",
                  :id="field.name",
                  :disabled="true"
                )
                  span(slot="noOptions")
                    i {{ notifies.no_options_list }}

              span.row-value(v-else-if="field.type === 'file'")
                existing-files-area(:parentData="{ files: row[field.value] }")

              .row.row-value-extra(v-else-if="field.extra")
                span.row-value {{ handleRow(row, field) }}
                q-btn.show-more-button(unelevated, no-caps, @click="switchAdditionalInfoOpen(field)")
                  .block {{ field.extra.label }}
                  inline-svg.q-ml-sm(:src="require('../../../assets/icons/chevron.svg')")
                .bg-after(v-if="isBlockOpened(field.value)")

              a(v-else-if="field.type === 'link'", target="_blank", :href="row[field.value]") {{ getLinkFieldValue(row[field.value]) }}

              span.row-value(v-else) {{ handleRow(row, field) }}

              dynamic_issues_extra_tabs(
                v-if="isBlockOpened(field.value)",
                :isOpened="isBlockOpened(field.value)",
                :field="field",
                :showEmpty="!hiddenBlocks.includes(block.class)"
              )

    details-btn(
      v-if="block.details_btn",
      @change-show-details="changeShowDetails",
      :parentData="{ block, show_details: showDetails, label: block.details_btn_label }"
    )
</template>

<script setup>
import { ref, computed } from "vue";

import detailsBtn from "./details_btn";
import existingFilesArea from "../helpers/existingFilesArea";
import multiselect from "vue-multiselect";

import { confirmModalLocales, issueLocales } from "@/services/useLocales";
import { handleDateRow, handleDateTimeRow } from "@/services/reformatStrings";

import dynamic_issues_extra_tabs from "./dynamic_issues_extra_tabs";

const props = defineProps({
  /*
  Объект основных данных.

  Пример данных:
  'parentData': {
    "row": {
      "id": 118416,
      "external_id": "",
      "type": "MaintenanceIssue",
      "urgency": "Нормальная",
      "description": "<p>гео</p>",
      "state": "назначен в работу",
      "terminated": false,
      "state_translation": "назначен в работу",
      "service_id": 9,
      "workflow_id": 4,
      "service_title": "Инженерные системы",
      "user_id": 1115,
      "company_name": "СовТех",
      "building_full_title": "Офис - Кутузовский, г Москва, Кутузовский пр-кт, д 32",
      "building_title": "Офис - Кутузовский / г Москва, Кутузовский пр-кт, д 32",
      "building_address": "г Москва, Кутузовский пр-кт, д 32",
      "executor_full_name": "Nathanial Adams Kirlin",
      "tel": "",
      "email": "benny@rice",
      "declarer_full_name": "Ворончихина Кристина",
      "work_category_title": "Проблемы с канализацией ",
      "dead_line": "2024-09-27T13:15:00.000Z",
      "finish_date_plane": "2024-10-02T13:15:00.000Z",
      "overdue": false,
      "pre_overdue": false,
      "first_ppr_equipment": null,
      "contract_id": null,
      "contract_title": null,
      "work_title": null,
      "room_title": null,
      "user_full_name": "Ворончихина Кристина",
      "responsible_full_name": null,
      "created_at": "2024-09-27T13:15:54.507Z",
      "updated_at": "2024-09-27T13:16:00.863Z",
      "finished_at": null,
      "participants": 0,
      "work_place": "",
      "percentage_completion": 0,
      "priority_title": null,
      "priority_weight_db": null,
      "priority_weight": null,
      "rating": null,
      "checklist_id": null,
      "available_transitions": [
        "141"
      ],
      "available_activities": [],
      "actions_by_checkbox": [
        {
          "key": "executor_changing",
          "name": "Выбрать исполнителя"
        },
        {
          "key": "destroy",
          "name": "Удалить"
        }
      ],
      "can_change_executor": true
    },
    "show_details": false,
    "data": [
      {
        "class": "description-block",
        "style": {
          "width": "100%"
        },
        "parts": [
          [
            {
              "label": "Описание",
              "value": "description",
              "type": "description"
            }
          ]
        ]
      },
      {
        "class": "main-block",
        "name": "Основная информация",
        "parts": [
          [
            {
              "label": "Сервис",
              "value": "service_title"
            },
            {
              "label": "Услуга",
              "value": "work_category_title"
            }
          ],
          [
            {
              "label": "Организация",
              "value": "company_name"
            },
            {
              "label": "Создатель",
              "value": "user_full_name"
            }
          ],
          [
            {
              "label": "Заявитель",
              "value": "declarer_full_name"
            },
            {
              "label": "Исполнитель",
              "value": "executor_full_name"
            }
          ],
          [
            {
              "label": "Строение",
              "value": "building_full_title",
              "extra": {
                "label": "Подробнее",
                "template_id": 17,
                "object_id": 6,
                "path": "extra_object_information"
              }
            }
          ],
          [
            {
              "label": "Помещение",
              "value": "room_title"
            }
          ],
          [
            {
              "label": "ID внешней системы",
              "value": "external_id"
            }
          ],
          [
            {
              "label": "Оборудование",
              "value": "first_ppr_equipment"
            }
          ]
        ]
      },
      {
        "class": "additional-block",
        "name": "Прочая информация",
        "parts": [
          [
            {
              "label": "Приоритет",
              "value": "priority_weight"
            },
            {
              "label": "Место проведения",
              "value": "work_place"
            }
          ],
          [
            {
              "label": "Дата создания",
              "value": "created_at",
              "type": "datetime",
              "format": "%d.%m.%Y, %H:%M"
            },
            {
              "label": "Дата обновления",
              "value": "updated_at",
              "type": "datetime",
              "format": "%d.%m.%Y, %H:%M"
            }
          ],
          [
            {
              "label": "Начало (план)",
              "value": "dead_line",
              "type": "datetime",
              "format": "%d.%m.%Y, %H:%M"
            },
            {
              "label": "Завершения (план)",
              "value": "finish_date_plane",
              "type": "datetime",
              "format": "%d.%m.%Y, %H:%M"
            }
          ],
          [
            {
              "label": "Дата исполнения",
              "value": "finished_at",
              "type": "datetime",
              "format": "%d.%m.%Y, %H:%M"
            },
            {
              "label": "Срочность",
              "value": "urgency"
            }
          ]
        ]
      }
    ]
  }
  */
  parentData: {
    type: Object,
    default: () => {},
  },
});

const data = ref(props.parentData.data);
const showDetails = ref(props.parentData.show_details);
const hiddenBlocks = ref(props.parentData.data.map(el => el.class));
const extraInfoBlocksOpen = ref([]);

const row = computed(() => props.parentData.row);

const isBlockHidden = block => {
  return block.hidden ? showDetails.value : true;
};

const isBlockOpened = name => {
  return extraInfoBlocksOpen.value.findIndex(el => el === name) !== -1;
};

const switchHiddenBlockHidden = className => {
  const index = hiddenBlocks.value.findIndex(el => el === className);

  if (index !== -1) hiddenBlocks.value.splice(index, 1);
  else hiddenBlocks.value.push(className);
};

const switchAdditionalInfoOpen = field => {
  const index = extraInfoBlocksOpen.value.findIndex(el => el === field.value);

  if (index !== -1) extraInfoBlocksOpen.value.splice(index, 1);
  else extraInfoBlocksOpen.value.push(field.value);
};

const handleRow = (localRow, field) => {
  let result;

  if (field.type === "date" && localRow[field.value]) result = handleDateRow(localRow, field.value, field.format);
  else if (field.type === "datetime" && localRow[field.value])
    result = handleDateTimeRow(localRow, field.value, field.format);
  else if (field.type === "boolean") {
    if (typeof localRow[field.type] === "string") {
      result = localRow[field.value];
    } else {
      if (localRow[field.value] === null) result = null;
      else if (localRow[field.value]) result = confirmModalLocales.value.submit;
      else result = confirmModalLocales.value.cancel;
    }
  } else result = localRow[field.value];

  return result || "-";
};

const changeShowDetails = val => {
  showDetails.value = val;
};

const isFieldHidden = (block, val) => {
  const isEntireBlockVisible = !hiddenBlocks.value.includes(block.class);
  const isValNotNull = row.value[val] !== null;
  const isValNotEmptyString = row.value[val] !== "";
  const isValNotEmptyArray = Array.isArray(row.value[val]) ? row.value[val].length : true;

  return isEntireBlockVisible || (isValNotNull && isValNotEmptyString && isValNotEmptyArray);
};

const getLinkFieldValue = value => {
  if (!value) {
    return;
  }
  if (value.length >= 50) {
    value = value.slice(0, 50);
    value += "...";
  }
  return value;
};
</script>

<style lang="scss">
@import "../../../assets/styles/show/blocks";
.flex {
  display: flex;
}

.row-title {
  font-size: 16px;
  font-weight: 600;
  color: var(--header-title-color);
}

.row-remove-empty {
  color: var(--header-title-color);
}

.show-card-info {
  .row-info-parts {
    gap: 16px;

    .row-info-field {
      width: 100% !important;
      margin: 0 !important;

      .row-label {
        margin-top: 10px;
      }
    }
  }
  .row-value-extra {
    gap: 16px;
    position: relative;

    span {
      flex-grow: 1;
    }

    .show-more-button {
      padding-left: 12px;
      padding-right: 12px;
      background-color: var(--show-card-expanded-panel);
      color: #5b7aff;
      width: 132px;
      border-radius: 8px;
      font-weight: 600;
      position: relative;
      z-index: 1;
    }

    .bg-after {
      position: absolute;
      height: 26px;
      width: 159px;
      background: var(--show-card-expanded-panel);
      bottom: -16px;
      right: 0;
      -webkit-mask: radial-gradient(27px at 0 0, rgba(0, 0, 0, 0) 98%, #000);
      mask: radial-gradient(27px at 0 0, rgba(0, 0, 0, 0) 98%, #000);
      z-index: 0;
    }
  }
  .container {
    position: relative;
  }
}

.show-card {
  &-blocks {
    display: flex;
    flex-direction: column;
    gap: 32px;
  }
}
</style>
