<template lang="pug">
div
  component(
    v-for="field in fields",
    :key="field.name",
    :is="getFieldComponent(field.type)",
    @fields-loaded="fieldsLoaded",
    :parentData="{ grid: grid, data: getFieldData(field), allFields: fields }",
    @fieldIsForbidden="fieldIsForbidden"
  )
</template>

<script>
import fields from "../forms/fields";

import { useEmitter } from "@/services/useEmitter";

export default {
  components: {
    fields,
  },

  props: {
    parentData: {
      type: Object,
      default: () => {},
    },
  },
  data: function () {
    return {
      action: this.parentData.action,
      row: this.parentData.row,
      fields: this.parentData.data,
      grid: this.parentData.grid,

      loading_fields: [],
      loading: true,
      emitter: useEmitter(),
    };
  },

  created() {
    this.fieldsLoaded();
  },

  methods: {
    getFieldComponent(key) {
      return fields[key];
    },

    getFieldData(field) {
      return this.fields.filter(f => f === field);
    },

    dataHasLoaded() {
      return !this.loading;
    },

    fieldIsForbidden(name) {
      // нам нужно установить в данные по полю с бэкенда forbidden,
      // чтобы при watch мы могли отсеять поле и уменьшить количество родителей.
      // мы не можем сделать это напрямую в select_field, потому что у нас несколько экземпляров селектов,
      // которые объединены общим пропом allFields, содержащим первичные данные с бэка
      const index = this.fields.findIndex(el => el.name === name);
      if (index !== -1) {
        this.fields[index].value = null;
        this.fields[index].forbidden = true;
      }
    },

    fieldsLoaded(field = undefined) {
      if (this.loading_fields.length === 0) {
        this.loading_fields = this.fields
          .filter(f => this.$store.state.fields_with_options.includes(f.type))
          .map(f => [f.type, f.name]);
      }

      if (field) {
        this.loading_fields = this.loading_fields.filter(f => f.sort().toString() !== field.sort().toString());
      }

      if (this.loading_fields.length === 0) {
        this.loading = false;
        this.emitter.emit("allFieldsLoaded");
      }

      this.$emit("loading-form", this.loading);
    },
  },
};
</script>

<style lang="scss"></style>
